import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CartComponent } from './components/cart/cart.component';
import { OverlayRef } from '@angular/cdk/overlay/overlay-ref';

@Injectable()
export class CartService {

    protected overlayRef: OverlayRef | undefined;

    constructor(
        protected readonly overlay: Overlay,
    ){ }

    public init(): void {

        if (this.overlayRef) { return; }

        let width = 600;
        const height = 400;
        const offset = 40;
        const positionBuilder = this.overlay.position();
        let positionStrategy = positionBuilder.global().bottom(- 0 - height + offset + 'px').right('48px');
        if (window.innerWidth < 600){
            width = window.innerWidth;
            positionStrategy = positionBuilder.global().bottom(- 0 - height + offset + 'px').right('0');
        }
        this.overlayRef = this.overlay.create({
            height: `${height}px`,
            width: `${width}px`,
            hasBackdrop: false,
            positionStrategy,
        });

        this.overlayRef.backdropClick().subscribe(() => this.overlayRef.addPanelClass('foo'));

        const cartComponentPortal = new ComponentPortal(CartComponent);
        this.overlayRef.attach(cartComponentPortal);
    }

    public dispose(): void {
        if (!this.overlayRef) { return; }
        this.overlayRef.dispose();
        this.overlayRef = undefined;
    }

}
