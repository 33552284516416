import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IconsStore } from './icons.store';
import { SessionQuery } from '../session/session.query';
import { Icon } from '../../models/interfaces/icon.model';
import { Filter } from '../../models/interfaces/filter.model';

@Injectable({
    providedIn: 'root',
})
export class IconsService {

    public icons$: Observable<Array<Icon>> = this.sessionQuery.filter$
        .pipe(
            filter((f: Filter) => !!f),
            switchMap((f: Filter) => this.loadIconSet(f)),
        );

    constructor(
        protected readonly http: HttpClient,
        protected readonly store: IconsStore,
        protected readonly sessionQuery: SessionQuery,
    ) {
    }

    public loadIconSet(filter: Filter): Observable<Array<Icon>> {
        return this.http.get(`assets/${filter.name}.json`)
            .pipe(
              tap((icons: Array<Icon>) => this.store.upsertMany(icons)),
            );
    }

    public loadIconSVGSource(icon: Icon): Observable<string> {
        return this.http.get(`assets/${icon.type}/${icon.category}/${icon.path}`, {responseType: 'text'})
            .pipe(
                take(1),
            );
    }

    public updateIcon(icon: Icon, newState: Partial<Icon>): void {
        this.store.update((e) => e.id === icon.id, {
            ...icon,
            ...newState,
        });
    }

    public updateMany(icons: Array<Icon>, newState: Partial<Icon>): void {
        this.store.update((e) => !!icons.find(icon => icon.id === e.id), newState);
    }

    public updateAll(newState: Partial<Icon>): void {
        this.store.update(null, newState)
    }
}
