import { Component, OnInit } from '@angular/core';
import { CartItemsQuery } from '../../../shared/state/cart/cart-items.query';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { CartItemsService } from '../../../shared/state/cart/cart-items.service';
import { Icon } from '../../../shared/models/interfaces/icon.model';

@Component({
  selector: 'iconic-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

    public cartItems$: Observable<{[key: number]: Array<Icon>}> = of([]);
    public opened: boolean = false;
    public addAnimation: boolean;
    public cartItems: Array<Icon> = [];

    constructor(
        protected readonly cartItemsQuery: CartItemsQuery,
        protected readonly cartItemsService: CartItemsService,
    ) { }

    ngOnInit(): void {
        this.cartItems$ = this.cartItemsQuery.selectAll()
            .pipe(
                tap(cartItems => {
                    if (cartItems.length > this.cartItems.length) {
                        // this.displayAddAnimation();
                    }
                    this.cartItems = cartItems;
                }),
                map(cartItems => this.groupByCategory(cartItems)),
            );
    }

    protected displayAddAnimation(): void {
        this.addAnimation = true;
        setTimeout(() => this.addAnimation = false, 500);
    }

    public remove(icon: Icon): void {
        this.cartItemsService.remove(icon);
    }

    public removeAll(): void {
        this.opened = false;
        this.cartItemsService.removeAll();
    }

    public toggleOpened(event: MouseEvent): void {
        event.stopPropagation();
        this.opened = !this.opened;
    }

    public downloadSelected(): void {
        this.cartItemsQuery.selectAll()
            .pipe(
                take(1),
                switchMap(cartItems => this.cartItemsService.download(cartItems)),
            )
            .subscribe(() => this.removeAll());
    }

    public groupByCategory(icons: Array<Icon>): {[key: number]: Array<Icon>} {
        return _.groupBy(icons, 'category');
    }
}
