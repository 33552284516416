import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IconsState } from './icons.state';
import { IconsStore } from './icons.store';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SessionQuery } from '../session/session.query';
import { Icon } from '../../models/interfaces/icon.model';
import { IconSet } from '../../models/interfaces/icon-set.model';
import { Filter } from '../../models/interfaces/filter.model';

@Injectable({
    providedIn: 'root',
})
export class IconsQuery extends QueryEntity<IconsState> {

    public icons$: Observable<Array<Icon>> = this.sessionQuery.filter$
        .pipe(
            switchMap((filter: Filter) => this.selectAll({
                filterBy: entity => {
                    return entity.type === filter.type
                        && entity.iconSet === filter.name
                        && entity.name.toLowerCase().includes(filter.value.toLowerCase());
                },
            })
                .pipe(
                    map(icons => icons.filter(icon => filter.categories.length === 0 ? true : filter.categories.includes(icon.category))),
                )),
        );

    constructor(
        protected readonly store: IconsStore,
        protected readonly sessionQuery: SessionQuery,
    ) {
        super(store);
    }
}
