import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridListComponent } from './components/grid-list/grid-list.component';
import { GridViewComponent } from './grid-view.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatGridListModule } from '@angular/material/grid-list';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRippleModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { SafeHtmlModule } from '../shared/pipes/safe-html/safe-html.module';
import { GridListItemComponent } from './components/grid-list-item/grid-list-item.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatExpansionModule } from '@angular/material/expansion';
import { DirectivesModule } from '../shared/directives/directives.module';
import { IconModule } from '../shared/components/icon/icon.module';

@NgModule({
    declarations: [
        GridListComponent,
        GridViewComponent,
        GridListItemComponent,
    ],
    imports: [
        CommonModule,
        ClipboardModule,
        MatGridListModule,
        ScrollingModule,
        SafeHtmlModule,
        MatSnackBarModule,
        MatRippleModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatSelectModule,
        FormsModule,
        MatExpansionModule,
        DirectivesModule,
        IconModule,
    ],
    exports: [
        GridViewComponent,
    ]
})
export class GridModule { }
