import { Injectable } from '@angular/core';
import { SessionStore } from './session.store';
import { IconSet } from '../../models/interfaces/icon-set.model';
import { Filter } from '../../models/interfaces/filter.model';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    constructor(
        protected readonly store: SessionStore,
    ) {}

    public updateIconSets(iconSets: Array<IconSet>): void {
        this.store.update({ iconSets });
    }

    public updateSelected(selected: IconSet): void {
        this.store.update({ selected });
    }

    public updateFilter(filter: Partial<Filter>): void {
        this.store.update(state => ({
            filter: {
                ...state.filter,
                ...filter,
            },
        }));
    }
}
