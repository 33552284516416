import { Injectable } from '@angular/core';
import { CartItemsStore } from './cart-items.store';
import { IconsService } from '../icons/icons.service';
import { combineLatest, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Icon } from '../../models/interfaces/icon.model';

@Injectable({
    providedIn: 'root',
})
export class CartItemsService {

    constructor(
        protected readonly store: CartItemsStore,
        protected readonly iconsService: IconsService,
    ) { }

    public addCategory(icons: Array<Icon>): void {
        this.store.add(icons);
        this.iconsService.updateMany(icons, { selected: true });
    }

    public add(icon: Icon): void {
        this.store.add(icon);
        this.iconsService.updateIcon(icon, { selected: true });
    }

    public remove(icon: Icon): void {
        this.store.remove(e => e.id === icon.id);
        this.iconsService.updateIcon(icon, { selected: false });
    }

    public removeAll(): void {
        this.store.remove();
        this.iconsService.updateAll({ selected: false });
    }

    public download(cartItems: Array<Icon>): Observable<any> {
        const zip = new JSZip();
        const subscribers = cartItems.map(icon => this.iconsService.loadIconSVGSource(icon));
        return combineLatest(
            subscribers,
        )
            .pipe(
                take(1),
                tap(svgs => {
                    svgs.forEach((svg: string, index: number) => {
                        zip.file(cartItems[index].path, svg);
                    });
                    zip
                        .generateAsync({type:'blob'})
                        .then((content) => {
                            saveAs(content, `${cartItems[0].iconSet}.zip`)
                        });
                })
            );

    }

}
