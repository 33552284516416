import { AfterContentInit, Component } from '@angular/core';
import { SessionService } from './shared/state/session/session.service';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { IconTypesEnum } from './shared/models/enums/icon-types.enum';
import * as Parallax from 'parallax-js';
declare const Parallax: any;

@Component({
  selector: 'iconic-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentInit{

    constructor(
        protected readonly http: HttpClient,
        protected readonly sessionService: SessionService,
    ) {
        this.http.get('assets/config.json')
            .pipe(
                tap((data: any) => this.sessionService.updateIconSets(data.iconSets)),
                tap((data: any) => this.sessionService.updateSelected({
                    ...data.iconSets[0],
                    type: IconTypesEnum.OUTLINE,
                })),
                tap((data: any) => this.sessionService.updateFilter({
                    name: data.iconSets[0].name,
                    value: '',
                    type: IconTypesEnum.OUTLINE,
                    categories: [],
                })),
            )
            .subscribe();
    }

    public goToLink(url: string): void {
        if (url === 'github') {
            window.open('https://git.team23.de/team23/team23-icons');
        } else if (url === 'figma') {
            window.open('https://www.figma.com/file/aZkIepG58PGI2aC4Bf1UgF/TEAM23-Icon-Set?node-id=0%3A1');
        } else if (url === 'team23') {
            window.open('https://www.team23.de');
        }
    }

    public ngAfterContentInit(): void {
        const foo = new Parallax(document.getElementById('scene1'), {
            relativeInput: true,
            hoverOnly: true,
        });
        const doo = new Parallax(document.getElementById('scene2'), {
            relativeInput: true,
            hoverOnly: true,
        });
    }
}
