import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'iconic-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {

    @Input() public color: string = '#d8e20f';

}
