import { Query } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { SessionState } from './session.state';
import { SessionStore } from './session.store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { IconSet } from '../../models/interfaces/icon-set.model';

@Injectable({
    providedIn: 'root',
})
export class SessionQuery extends Query<SessionState> {
    constructor(protected store: SessionStore) {
        super(store);
    }

    public iconSets$: Observable<Array<string>> = this.select('iconSets')
        .pipe(
            map((iconSets: Array<IconSet>) => iconSets.map(set => set.name)),
        );

    public categories$: Observable<Array<string>> = this.select('iconSets' )
        .pipe(
            map((iconSets: Array<IconSet>) => {
                return iconSets.reduce((total: Array<string>, current: IconSet) => [...total, ...current.categories], []);
            }),
        );

    public selected$ = this.select('selected');
    public filter$ = this.select('filter');

    public selectedCategories$ = this.select('selected')
        .pipe(
            filter(iconSet => !!iconSet),
            map((iconSet: IconSet) => iconSet.categories),
        )
}
