import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { persistState } from '@datorama/akita';

enableProdMode();
persistState();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
