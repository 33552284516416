import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { CartModule } from './cart/cart.module';
import { DirectivesModule } from './shared/directives/directives.module';
import { GridModule } from './grid/grid.module';
import { GridViewComponent } from './grid/grid-view.component';
import { ButtonModule } from './shared/components/button/button.module';

const routes: Routes = [
    {
        path: '',
        component: GridViewComponent,
    },
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(routes),
        BrowserAnimationsModule,
        MatButtonModule,
        HttpClientModule,
        CartModule,
        DirectivesModule,
        GridModule,
        ButtonModule,
    ],
    bootstrap: [
        AppComponent,
    ]
})
export class AppModule {}
