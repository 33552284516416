import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { CartItemsState } from './cart-items.state';

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'cartItems' })
export class CartItemsStore extends EntityStore<CartItemsState> {
    constructor() {
        super();
    }
}
