import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Icon } from '../../../shared/models/interfaces/icon.model';
import { IconsService } from '../../../shared/state/icons/icons.service';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'iconic-grid-list-item',
    templateUrl: './grid-list-item.component.html',
    styleUrls: ['./grid-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridListItemComponent {

    private _icon: Icon;
    public svgSource: string = '';
    public svgSource$: Observable<string>;

    @Input() public set icon(icon: Icon) {
        this._icon = icon;
    };
    public get icon() {
        return this._icon;
    }

    @Output() protected readonly add: EventEmitter<Icon> = new EventEmitter<Icon>();
    @Output() protected readonly remove: EventEmitter<Icon> = new EventEmitter<Icon>();
    @Output() protected readonly copyToClipboard: EventEmitter<{ icon: Icon, svgSource: string }>
        = new EventEmitter<{ icon: Icon, svgSource: string }>();

    constructor(
        protected readonly cdr: ChangeDetectorRef,
        protected readonly iconsService: IconsService,
    ) {
    }

    public copy(icon: Icon, svgSource: string) {
        this.copyToClipboard.emit({ icon, svgSource });
    }

    public emitAdd(event: MouseEvent, icon: Icon): void {
        event.stopPropagation();
        this.add.emit(icon);
    }

    public emitRemove(event: MouseEvent, icon: Icon): void {
        event.stopPropagation();
        this.remove.emit(icon);
    }

    public onVisible(): void {
        this.svgSource$ = this.iconsService.loadIconSVGSource(this.icon)
            .pipe(
                distinctUntilChanged((prev, curr) => prev === curr),
                tap(src => this.svgSource = src),
            );
    }
}
