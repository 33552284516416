import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, filter, startWith, switchMap, take, tap } from 'rxjs/operators';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FormControl } from '@angular/forms';

import { IconsService } from '../shared/state/icons/icons.service';
import { IconsQuery } from '../shared/state/icons/icons.query';
import { SessionQuery } from '../shared/state/session/session.query';
import { SessionService } from '../shared/state/session/session.service';
import { CartItemsQuery } from '../shared/state/cart/cart-items.query';
import { CartService } from '../cart/cart.service';
import { Icon } from '../shared/models/interfaces/icon.model';
import { IconTypesEnum } from '../shared/models/enums/icon-types.enum';

@Component({
    templateUrl: 'grid-view.component.html',
    styleUrls: ['grid-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridViewComponent implements OnInit {

    public loading: boolean = true;

    public icons$: Observable<Array<Icon>>;

    public searchInputCtrl: FormControl = new FormControl([]);
    public selectedCategories = [];

    public categories$: Observable<any> = of();
    public selectedIconSet: string = '';

    protected selectedIconType: string = IconTypesEnum.OUTLINE;

    constructor(
        protected readonly iconsService: IconsService,
        protected readonly iconsQuery: IconsQuery,
        protected readonly sessionQuery: SessionQuery,
        protected readonly sessionService: SessionService,
        protected readonly cartItemsQuery: CartItemsQuery,
        protected readonly cartService: CartService,
    ) {}

    public ngOnInit(): void {
        this.icons$ = this.iconsQuery.icons$
            .pipe(
                tap(() => this.loading = false),
            );

        this.categories$ = this.sessionQuery.selectedCategories$;
        this.sessionQuery.iconSets$
            .pipe(
                tap(iconSets => this.selectedIconSet = iconSets[0]),
                take(1),
            )
            .subscribe();
        this.iconsService.icons$.subscribe();

        this.searchInputCtrl.valueChanges
            .pipe(
                startWith(''),
                debounceTime(300),
                tap(() => this.loading = true),
                tap(value => this.sessionService.updateFilter({ value })),
            )
            .subscribe();

        this.cartItemsQuery.selectAll()
            .subscribe(cartItems => cartItems.length !== 0 ? this.cartService.init() : this.cartService.dispose());
    }

    public onToggleChange(toggle: MatSlideToggleChange): void {
        this.selectedIconType = toggle.checked ? IconTypesEnum.SOLID : IconTypesEnum.OUTLINE;
        this.loading = true;
        this.sessionService.updateFilter({
            name: this.selectedIconSet,
            type: this.selectedIconType,
        });
    }

    public onSelectionChange(selectedIconSet: IconTypesEnum) {
        this.selectedIconSet = selectedIconSet;
        this.loading = true;
        this.sessionService.updateFilter({
            name: this.selectedIconSet,
            type: this.selectedIconType,
        });
    }

    public onCategoryChange(selection: any) {
        this.selectedCategories = selection.value;
        this.loading = true;
        this.sessionService.updateFilter({
            name: this.selectedIconSet,
            type: this.selectedIconType,
            categories: this.selectedCategories,
        });
    }
}
