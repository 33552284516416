import {
  ElementRef, Output, Directive, AfterViewInit, OnDestroy, Input, EventEmitter, HostBinding, ChangeDetectorRef
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { animate, AnimationBuilder, AnimationMetadata, style } from '@angular/animations';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[inView]'
})
export class InViewDirective implements AfterViewInit, OnDestroy {
  protected _elementClass: string[] = [];
  protected readonly destroyed$: Subject<void> = new Subject<void>();

  protected elementPos: number;
  protected elementHeight: number;

  protected scrollPos: number;
  protected windowHeight: number;

  @Input() protected readonly inView: string;
  @Output() protected readonly visible: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('class')
  get elementClass(): string {
    return this._elementClass.join(' ');
  }
  set(val: string) {
    this._elementClass = val.split(' ');
  }

  constructor(
    private element: ElementRef,
    protected readonly builder: AnimationBuilder,
    protected readonly cdr: ChangeDetectorRef,
  ) {

  }

  private saveDimensions(): void {
    this.elementPos = this.getOffsetTop(this.element.nativeElement);
    this.elementHeight = this.element.nativeElement.offsetHeight;
    this.windowHeight = window.innerHeight;
  }
  private saveScrollPos(): void {
    this.scrollPos = window.scrollY;
  }
  private getOffsetTop(element: any): number {
    let offsetTop = element.offsetTop || 0;
    if (element.offsetParent) {
      offsetTop += this.getOffsetTop(element.offsetParent);
    }
    return offsetTop;
  }
  private checkVisibility(): void {
    if (this.isVisible()) {
      // double check dimensions (due to async loaded contents, e.g. images)
      this.saveDimensions();
      if (this.isVisible()) {
        this.destroyed$.next();

        this._elementClass.push('visible');
        this.visible.emit();

        this.cdr.detectChanges();
      }
    }
  }
  private isVisible(): boolean {
    return this.scrollPos >= this.elementPos || (this.scrollPos + this.windowHeight + 400) >= (this.elementPos + this.elementHeight);
  }

  public ngAfterViewInit(): void {
    fromEvent(window, 'scroll')
      .pipe(
        startWith(null),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.saveScrollPos();
        this.checkVisibility();
      });
    fromEvent(window, 'resize')
      .pipe(
        startWith(null),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.saveDimensions();
        this.checkVisibility();
      });
  }

  private animate(direction: string): Array<AnimationMetadata> {
    return [
      style({
        opacity: 0,
        transform: direction === 'rightToLeft' ? 'translateX(100px)' : 'translateX(-100px)'
      }),
      animate('400ms ease-in', style({
        opacity: 1,
        transform: 'translateX(0)',
      })),
    ];
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
