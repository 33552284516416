import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartComponent } from './components/cart/cart.component';
import { CartService } from './cart.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatChipsModule } from '@angular/material/chips';
import { ButtonModule } from '../shared/components/button/button.module';
import { IconModule } from '../shared/components/icon/icon.module';

@NgModule({
    declarations: [
        CartComponent,
    ],
    imports: [
        CommonModule,
        OverlayModule,
        MatChipsModule,
        ButtonModule,
        IconModule,
    ],
    providers: [
        CartService,
    ],
    exports: [
        CartComponent,
    ]
})
export class CartModule { }
