import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CartItemsService } from '../../../shared/state/cart/cart-items.service';
import { Icon } from '../../../shared/models/interfaces/icon.model';
import * as _ from 'lodash';
import { startWith, take, takeUntil } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Component({
    selector: 'iconic-grid-list',
    templateUrl: './grid-list.component.html',
    styleUrls: ['./grid-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridListComponent implements AfterViewInit {

    public _icons: {[key: number]: Array<Icon>};
    public gridListCols: number = 6;

    @Input() public set icons (icons: Array<Icon>) {
        this._icons = this.groupByCategory(icons);
    };

    constructor(
        protected readonly clipboard: Clipboard,
        protected readonly toast: MatSnackBar,
        protected readonly cartItemsService: CartItemsService,
        protected readonly el: ElementRef,
    ) {

    }

    public ngAfterViewInit(): void {
        fromEvent(window, 'resize')
            .pipe(
                startWith(null),
            )
            .subscribe(() => {
                this.gridListCols = Math.floor(this.el.nativeElement.clientWidth / 150);
            });
    }

    public copyToClipboard(event: { icon: Icon, svgSource: string }): void {
        if (this.clipboard.copy(event.svgSource)) {
            this.toast.open(`Icon ${event.icon.name} successfully copied.`, 'OK', {
                duration: 2000,
            });
        }
    }

    public addCategory(icons: Array<Icon>, event?: MouseEvent): void {
        if (event) { event.stopPropagation(); }
        this.cartItemsService.addCategory(icons);
    }

    public addToCart(icon: Icon): void {
        this.cartItemsService.add(icon);
    }

    public removeFromCart(icon: Icon): void {
        this.cartItemsService.remove(icon);
    }

    public trackById(_: number, item: Icon) {
        return item.id;
    }

    public trackByCategory(_: number, item: { category: string; icons: Array<Icon>}) {
        return item.category;
    }

    public groupByCategory(icons: Array<Icon>): {[key: number]: Array<Icon>} {
        return _.groupBy(icons, 'category');
    }

    public downloadCategory(icons: Array<Icon>, event?: MouseEvent) {
        if (event) { event.stopPropagation(); }
        this.cartItemsService.download(icons)
            .pipe(
                take(1),
            )
            .subscribe();
    }
}
