import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { IconsState } from './icons.state';

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'icons' })
export class IconsStore extends EntityStore<IconsState> {
    constructor() {
        super();
    }
}
