import { Store, StoreConfig } from '@datorama/akita';
import { SessionState } from './session.state';
import { Injectable } from '@angular/core';

export function createInitialState(): SessionState {
    return {
        filter: null,
        selected: null,
        iconSets: [],
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'session' })
export class SessionStore extends Store<SessionState> {
    constructor() {
        super(createInitialState());
    }
}
